import { Button, Label, Select } from 'theme-ui'
import React, { useState } from 'react'
import useClipboard from 'react-use-clipboard'
import { Flex, Text } from 'theme-ui'

const setInitialProduct = (producer) => {
  const product = producer.products[0]
  if (product) {
    return product.toLowerCase()
  } else {
    return 'karl'
  }
}

const productWebsites = {
  karl: 'https://karlbikes.com',
  louise: 'https://www.louise.insure',
}

export const ReferrerLinks = ({ producer }) => {
  const [product, setProduct] = useState(setInitialProduct(producer))
  const link = `${productWebsites[product]}/?ref=p${producer.referrerToken}&utm_campaign=producer_app`
  const [isCopied, setCopied] = useClipboard(link, { successDuration: 1000 })

  return (
    <>
      <Label htmlFor="product">Produkt</Label>
      <Select
        name="product"
        id="product"
        onChange={(e) => setProduct(e.target.value)}
      >
        {producer.products.map((productName) => (
          <option key={productName} value={productName.toLowerCase()}>
            {productName}
          </option>
        ))}
      </Select>
      <Flex
        sx={{
          alignItems: 'center',
          fontSize: '14px',
          mt: 3,
          p: 2,
          bg: 'gray.0',
        }}
      >
        <Text
          sx={{
            fontFamily: 'monospace',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            flex: 1,
            paddingRight: 2,
          }}
        >
          {link}
        </Text>
        <Button sx={{ ml: 'auto' }} variant="outline" onClick={setCopied}>
          {isCopied ? 'Kopiert!' : 'Kopieren'}
        </Button>
      </Flex>
    </>
  )
}
