import { Button } from "theme-ui";
import { Link as RouterLink, Router } from "@reach/router";
import PropTypes from "prop-types";
import { evolve, map, reduce } from "ramda";
import React from "react";
import { useQuery } from "react-query";
import { Box, Flex, Grid, Heading, Link, Spinner, Text } from "theme-ui";
import { Container, View } from "../components/shell";
import { DataHeading } from "../components";

export default function SettlementsApp({ producer, producerApi }) {
  return (
    <Router>
      <Settlement path="/:id" producer={producer} producerApi={producerApi} />
      <SettlementsList
        path="/"
        default
        producer={producer}
        producerApi={producerApi}
      />
    </Router>
  );
}

SettlementsApp.propTypes = {
  producer: PropTypes.object.isRequired,
  producerApi: PropTypes.func.isRequired,
};

function Settlement({ producerApi, producer, id }) {
  const { isLoading, data } = useQuery(["settlments", id], async () => {
    const data = await producerApi
      .get(`producers/${producer.producerId}/settlements/${id}`)
      .json();

    return evolveSettlement(data);
  });

  return (
    <View>
      <Container>
        <Flex sx={{ alignItems: "flex-end" }}>
          <Box>
            <Heading as="h1">Abrechnungen</Heading>
            <Text sx={{ fontSize: 3, color: "primary" }}>
              {data?.settlementNumber}
            </Text>
          </Box>
          <Box sx={{ marginLeft: "auto" }}>
            <Text>
              <Link as={RouterLink} to={`../`}>
                Zurück
              </Link>
            </Text>
          </Box>
        </Flex>
      </Container>
      <Container>
        {isLoading
          ? (
            <Flex my={5} sx={{ justifyContent: "center" }}>
              <Spinner size={24} color="primary" />
            </Flex>
          )
          : null}
        {data
          ? (
            <>
              <Grid columns={2}>
                <Grid gap={2}>
                  <Box>
                    <DataHeading>Abrechungsnummer</DataHeading>
                    <Text>{data.settlementNumber}</Text>
                  </Box>
                  <Box>
                    <DataHeading>Abrechnungszeitraum</DataHeading>
                    <Text as="span">{data.periodStartAt}</Text>
                    {" – "}
                    <Text as="span">{data.periodEndAt}</Text>
                  </Box>
                  <Box>
                    <DataHeading>Provision</DataHeading>
                    <Text>{data.total}</Text>
                  </Box>
                  <Box>
                    <DataHeading>Erstellungsdatum</DataHeading>
                    <Text>{data.insertedAt}</Text>
                  </Box>
                </Grid>
                <Flex sx={{ alignItems: "flex-end", justifyContent: "flex-end" }}>
                  <Button as={Link} href={data.path} download>
                    Herunterladen
                  </Button>
                </Flex>
              </Grid>
              <Box sx={{ marginTop: 5 }}>
                <Heading as="h3">Produkte</Heading>
                <Box
                  as="table"
                  sx={{
                    my: 3,
                    width: "100%",
                    borderCollapse: "collapse",
                    th: {
                      textAlign: "left",
                      borderBottom: "1px solid",
                      borderColor: "gray.3",
                    },
                    td: { borderBottom: "1px solid", borderColor: "gray.1" },
                  }}
                >
                  <thead>
                    <Box as="tr">
                      <Box as="th">
                        <DataHeading>Name</DataHeading>
                      </Box>
                      <Box as="th">
                        <DataHeading>Provision</DataHeading>
                      </Box>
                      <Box as="th">
                        <DataHeading>Prämien</DataHeading>
                      </Box>
                      <Box as="th">
                        <DataHeading>Download</DataHeading>
                      </Box>
                    </Box>
                  </thead>
                  <tbody>
                    {data?.rows.map((row) => {
                      return (
                        <Box as="tr" key={row.productId}>
                          <Box as="td">
                            <Text>{row.productName}</Text>
                          </Box>
                          <Box as="td">
                            <Money>{row.commission}</Money>
                          </Box>
                          <Box as="td">
                            <Money>{row.netPremiumSum}</Money>
                          </Box>
                          <Box as="td">
                            <Link href={row.path} download>
                              Auflistung
                            </Link>
                          </Box>
                        </Box>
                      );
                    })}
                  </tbody>
                </Box>
              </Box>
            </>
          )
          : null}
      </Container>
    </View>
  );
}

function SettlementsList({ producer, producerApi }) {
  const { isLoading, error, data } = useQuery(["settlments"], async () => {
    const data = await producerApi
      .get(`producers/${producer.producerId}/settlements`)
      .json();

    return map(evolveSettlement, data);
  });

  return (
    <View>
      <Container>
        <Flex>
          <Heading as="h1">Abrechnungen</Heading>
        </Flex>
      </Container>
      <Container>
        {isLoading
          ? (
            <Flex my={5} sx={{ justifyContent: "center" }}>
              <Spinner size={24} color="primary" />
            </Flex>
          )
          : null}
        {error ? <Text>Leider ist ein Fehler aufgetreten</Text> : null}
        {!isLoading && data?.length === 0
          ? (
            <Text sx={{ color: "muted" }}>
              Es sind noch keine Abrechnungen verfügbar. Du bekommst eine Email sobald es soweit ist.
            </Text>
          )
          : null}
        {data?.map((settlement) => (
          <Box
            key={settlement.id}
            sx={{
              paddingBottom: 3,
              marginBottom: 3,
              borderBottom: "1px solid",
              borderColor: "gray.1",
            }}
          >
            <Text as="h3" sx={{ mb: 3, fontSize: 2, fontWeight: "bold" }}>
              {settlement.settlementNumber}
            </Text>
            <Flex sx={{ mx: -3, mb: -3, flexWrap: "wrap" }}>
              <Box sx={{ px: 3, pb: 3 }}>
                <DataHeading>Abrechnungszeitraum</DataHeading>
                <Text as="span">{settlement.periodStartAt}</Text>
                {" – "}
                <Text as="span">{settlement.periodEndAt}</Text>
              </Box>
              <Box sx={{ px: 3, pb: 3 }}>
                <DataHeading>Provision</DataHeading>
                <Money>{settlement.total}</Money>
              </Box>
              <Box sx={{ px: 3, pb: 3 }}>
                <DataHeading>Erstellungsdatum</DataHeading>
                <Text>{settlement.insertedAt}</Text>
              </Box>
            </Flex>
            <Box sx={{ mt: 2 }}>
              <Link as={RouterLink} to={`./${settlement.id}`}>
                Abrechnung Anzeigen
              </Link>
            </Box>
          </Box>
        ))}
      </Container>
    </View>
  );
}

const Money = ({ children, ...props }) => (
  <Text as="span" sx={{ fontVariantNumeric: "lining-nums" }} {...props}>
    {children}
  </Text>
);

function formatDate(dateString) {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("de-AT").format(date);
}

function formatCurrency(minorUnit) {
  const majorUnit = minorUnit / 100;
  return new Intl.NumberFormat("de-AT", {
    style: "currency",
    currency: "EUR",
  }).format(majorUnit);
}

function evolveSettlement(settlement) {
  const total = formatCurrency(
    reduce(
      (acc, elem) => {
        acc = acc + parseInt(elem.commission, 10);
        return acc;
      },
      0,
      settlement.rows,
    ),
  );

  return evolve({
    periodStartAt: formatDate,
    periodEndAt: formatDate,
    insertedAt: formatDate,
    rows: map(
      evolve({
        commission: formatCurrency,
        netPremiumSum: formatCurrency,
      }),
    ),
  })({
    ...settlement,
    total,
  });
}
