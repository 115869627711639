import * as React from 'react'

// Exported from figma
// https://www.figma.com/file/o9NvfgPIVogvApJR9tJupd/Icons?node-id=0%3A1

export function LouiseIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M0 0h512v512H0z" />
      <path fill="#F3390D" d="M0 0h512v512H0z" />
      <path
        d="M69.163 226.784l160.694 168.931c6.241 6.57 14.865 10.285 23.879 10.285 9.014 0 17.638-3.715 23.879-10.285l163.147-171.51c10.658-11.195 12.276-28.332 3.904-41.36l-38.847-60.676c-6.126-9.475-16.579-15.182-27.783-15.169h-232.38c-9.787.01-19.073 4.375-25.381 11.933l-52.563 63.355c-10.813 13.039-10.188 32.205 1.451 44.496z"
        fill="#F3390D"
      />
      <path
        opacity={0.865}
        d="M69.163 226.784l160.694 168.931c6.241 6.57 14.865 10.285 23.879 10.285 9.014 0 17.638-3.715 23.879-10.285l163.147-171.51c10.658-11.195 12.276-28.332 3.904-41.36l-38.847-60.676c-6.126-9.475-16.579-15.182-27.783-15.169h-232.38c-9.787.01-19.073 4.375-25.381 11.933l-52.563 63.355c-10.813 13.039-10.188 32.205 1.451 44.496z"
        fill="#fff"
      />
      <path
        d="M254 405.998c9.187.069 18.002-3.588 24.393-10.118l164.155-169.028c12.011-12.326 12.652-31.644 1.483-44.723l-42.036-66.832c-6.393-7.538-27.564-8.297-37.587-8.297h-28.535L254 405.998z"
        fill="#F3390D"
        opacity={0.421}
      />
      <path d="M336 107H182l72.518 299" fill="#F3390D" opacity={0.18} />
    </svg>
  )
}

export function KarlIcon(props) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 512 512"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path fill="#fff" d="M0 0h512v512H0z" />
      <path fill="#00A3E0" d="M0 0h512v512H0z" />
      <ellipse
        rx={7.518}
        ry={7.524}
        transform="matrix(-1 0 0 1 151.877 406.476)"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M314.763 414H189.466c-4.26 0-7.517-3.26-7.517-7.524 0-4.263 3.257-7.524 7.517-7.524h72.672c-28.567-17.806-47.612-49.406-47.612-85.269 0-34.108 17.04-65.708 45.858-84.267 3.508-2.257 8.019-1.254 10.274 2.257 2.256 3.511 1.253 8.025-2.255 10.283-24.307 16.05-38.842 42.634-38.842 71.727 0 46.898 38.341 85.269 85.202 85.269s85.201-38.371 85.201-85.269c0-46.648-37.839-85.02-84.7-85.27-4.26 0-7.518-3.511-7.518-7.524 0-4.264 3.509-7.524 7.518-7.524 55.13.502 99.736 45.394 99.736 100.318C415 369.108 370.144 414 314.763 414z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M129.074 278.822c-15.287 2.257-27.566 14.797-29.57 30.346-2.757 22.822 16.539 42.134 39.092 39.124 15.036-2.006 27.565-14.044 30.071-29.092 1.504-9.029-.501-17.556-5.012-24.578-.501-1.003-.501-2.257.251-3.009l100.738-116.369c.752-.752 2.005-.501 2.256.502l40.596 139.943c1.002 3.26 4.009 5.517 7.267 5.517.752 0 1.754-.25 2.756-.501 3.509-1.505 5.263-5.768 4.26-9.53L277.424 157.94c-.25-.502-.25-1.003-.25-1.505l.752-40.879c0-1.505 1.253-2.508 2.506-2.508h31.574c14.284 0 26.814 10.533 28.067 24.828 1.754 16.553-11.277 30.346-27.315 30.346-4.761 0-8.269 4.013-7.518 8.778.752 3.762 4.01 6.27 7.769 6.27 24.307-.251 43.853-20.816 42.35-45.394C353.855 115.806 333.808 98 311.756 98h-41.348c-4.01 0-7.518 3.511-7.518 7.524l-.501 42.635c0 1.504-1.253 2.508-2.506 2.508H106.52c-4.26 0-7.768 3.762-7.518 8.025.25 4.013 4.01 7.022 8.019 7.022H250.36c1.253 0 1.755 1.254 1.003 2.007l-98.483 113.86c-.752.752-2.005 1.003-3.007.502-6.265-3.01-13.282-4.264-20.799-3.261zm5.262 14.797c11.026 0 20.047 9.029 20.047 20.064 0 11.034-9.021 20.063-20.047 20.063s-20.047-9.029-20.047-20.063c0-11.035 9.021-20.064 20.047-20.064z"
        fill="#fff"
      />
    </svg>
  )
}
