import { useAuth } from '@msc-insure/use-auth'
import React, { Suspense, useEffect } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { AppLoading } from '../components/shell'
import App from '../app'

function useHasMounted() {
  const [hasMounted, setHasMounted] = React.useState(false)
  useEffect(() => {
    setHasMounted(true)
  }, [])

  return hasMounted
}

const queryClient = new QueryClient()

function AppPage({ navigate }) {
  const hasMounted = useHasMounted()
  const auth = useAuth()
  const { error: authError, isAuthenticating, isAuthenticated } = auth

  useEffect(() => {
    if (!isAuthenticated()) navigate('/login')
  }, [isAuthenticated, navigate])

  useEffect(() => {
    if (authError) navigate('/login')
  }, [authError, navigate])

  return (
    <>
      {(!hasMounted || isAuthenticating) && <AppLoading />}
      {hasMounted && !isAuthenticated() && <AppLoading />}
      {hasMounted && isAuthenticated() && (
        <Suspense fallback={<AppLoading />}>
          <QueryClientProvider client={queryClient}>
            <App />
          </QueryClientProvider>
        </Suspense>
      )}
    </>
  )
}

export default AppPage
