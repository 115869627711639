import { Flex, Spinner } from "theme-ui";
import { useService } from "@xstate/react";
import React from "react";
import Form from "./form";

function FormPage({ service, producerDraftId }) {
  const [current] = useService(service);

  const draft = current.context.drafts.find((draft) => {
    return draft.producerDraftId === producerDraftId;
  });

  if (current.matches("fetch")) {
    return (
      <Flex
        sx={{
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner size={24} />
      </Flex>
    );
  }

  if (!draft && !current.matches("fetch")) {
    return <NotFound />;
  }

  return (
    <Form
      service={service}
      draftId={draft.producerDraftId}
      initialValues={draft}
    />
  );
}

function NotFound() {
  return <div>Konnte nicht gefunden werden</div>;
}

export default FormPage;
