import { useAuthToken } from "@msc-insure/use-auth";
import { Flex, Heading, Spinner } from "theme-ui";
import { Router } from "@reach/router";
import { useMachine } from "@xstate/react";
import { navigate } from "gatsby";
import React from "react";
import { Container, View } from "../../components/shell";
import { initContext, machine } from "./api-machine";
import FormPage from "./form-page";
import ManagePage from "./manage-page";
import SuccessPage from "./success-page";

const OnboardingPage = () => {
  const token = useAuthToken();

  const service = useMachine(
    machine.withContext(initContext({ token, navigate })),
  )[2];

  return (
    <View>
      <Container>
        <Heading as="h1">Partner</Heading>
      </Container>
      <Container>
        {service.initialized
          ? (
            <Router>
              <ManagePage service={service} path="/" />
              <FormPage
                service={service}
                path="/form/:producerDraftId/"
              />
              <SuccessPage service={service} path="/success" />
            </Router>
          )
          : (
            <Flex my={5} sx={{ justifyContent: "center" }}>
              <Spinner size={24} color="primary" />
            </Flex>
          )}
      </Container>
    </View>
  );
};

export default OnboardingPage;
