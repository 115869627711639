import { Delete16 } from "@carbon/icons-react";
import { Box, Button, Card, Divider, Flex, Grid, Input, Label, Link, Spinner, Text } from "theme-ui";
import { navigate } from "@reach/router";
import { useService } from "@xstate/react";
import React from "react";
import { useForm } from "react-hook-form";
import { Field, SectionHeading } from "../../components";

const ManagePage = ({ service }) => {
  const [current, send] = useService(service);
  const { formState, register, handleSubmit } = useForm();

  const { errors } = formState

  const createDraft = React.useCallback(
    (values) => send("CREATE", { data: { values } }),
    [send],
  );

  const deleteDraft = React.useCallback(
    (draft) => send("DELETE", { data: { draftId: draft.producerDraftId } }),
    [send],
  );

  return (
    <>
      <Box as="section" sx={{ mb: 5, fontSize: [2, 2, 3] }}>
        <SectionHeading>Neu anlegen …</SectionHeading>
        <Card
          as="form"
          bg="gray.0"
          onSubmit={handleSubmit(createDraft)}
          p={4}
          sx={{ fontSize: [2, 2, 3] }}
        >
          <Field>
            <Label htmlFor="company.name">Firmenname des neuen Partners</Label>
            <Input
              placeholder="Neuer Partner e.U."
              aria-invalid={!!errors.company && errors.company.name}
              {...register("company.name", { required: true })}
            />
          </Field>
          <Divider />
          <Flex
            mt={4}
            sx={{
              flexDirection: ["column", "row"],
              justifyContent: "space-between",
            }}
          >
            <Flex sx={{ alignItems: "center", flex: "none" }}>
              <Button disabled={current.matches("create")} type="submit">
                Erstellen
              </Button>
              {current.matches("create") && <Spinner ml={3} size={24} />}
            </Flex>
            <Text
              mt={[3, 0]}
              ml={[0, 5, 6]}
              sx={{ fontSize: 1, maxWidth: "40ch" }}
            >
              Wenn Du hier klickst wird ein neuer Partner angelegt und Du wirst zum Formular weitergeleitet um die
              restlichen Daten auszufüllen.
            </Text>
          </Flex>
        </Card>
      </Box>
      <Box as="section" sx={{ mb: 5 }}>
        <SectionHeading>Fortfahren …</SectionHeading>
        {current.matches("fetch") && (
          <Flex sx={{ alignItems: "center", justifyContent: "center" }}>
            <Spinner size={24} />
          </Flex>
        )}
        {current.context.drafts.length > 0 && (
          <Grid columns={[1, 2]} gap={4}>
            {current.context.drafts.map((draft) => (
              <ProducerDraftItem
                key={draft.producerDraftId}
                onDelete={deleteDraft}
                draft={draft}
              />
            ))}
          </Grid>
        )}
        {!current.matches("fetch") && current.context.drafts.length === 0 && (
          <Text variant="bold" color="muted">
            Du hast noch keine bestehenden Partnerentwürfe.
          </Text>
        )}
      </Box>
    </>
  );
};

function ProducerDraftItem({ onDelete, draft }) {
  const [deleting, setDeleting] = React.useState(false);
  const deleteDraft = React.useCallback(() => {
    setDeleting(true);
    onDelete(draft);
  }, [onDelete, setDeleting, draft]);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        opacity: deleting ? 0.6 : null,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <Text as="p" variant="bold" color="primary">
          <Link
            color="primary"
            sx={{
              fontSize: 2,
              cursor: "pointer",
              textDecoration: "none",
              "&:hover": {
                textDecoration: "underline",
              },
            }}
            onClick={() => navigate(`/app/onboarding/form/${draft.producerDraftId}`)}
          >
            {draft.company.name}
          </Link>
        </Text>
        {draft?.type ? `${draft.type}: ` : null}
        {draft.products
          && draft.products.map((product) => (
            <Text key={product} as="span" sx={{ pr: 1, fontWeight: "bold" }}>
              {product}
            </Text>
          ))}
        <Text as="p" color="muted">
          Erstellt am {new Intl.DateTimeFormat("de").format(new Date(draft.createdAt))}
        </Text>
      </Box>
      <Flex mt={4} sx={{ alignItems: "center" }}>
        <Button
          disabled={deleting}
          sx={{ fontSize: 0, svg: { marginLeft: 2 } }}
          onClick={deleteDraft}
          bg="warning.background"
          color="warning.text"
        >
          Verwerfen
          <Delete16 />
        </Button>
        {deleting && <Spinner ml={3} size={24} />}
      </Flex>
    </Card>
  );
}

export default ManagePage;
