import { useAuth, useAuthToken, useAuthSession } from '@msc-insure/use-auth'
import { Logout16 } from '@carbon/icons-react'
import { Button } from 'theme-ui'
import { Link as RouterLink, Router } from '@reach/router'
import { useMachine } from '@xstate/react'
import { Link as GatsbyLink } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import ky from 'ky'
import React from 'react'
import {
  Box,
  Divider,
  Flex,
  Grid,
  Heading,
  Link,
  Message,
  Spinner,
  Text,
} from 'theme-ui'
import { BrowserView, MobileView } from 'react-device-detect'
import { assign, Machine } from 'xstate'
import { Dashboard } from '../components/dashboard'
import FailureNotice from '../components/failure-notice'
import { ReferrerLinks } from '../components/referrer-link'
import * as ProductIcons from '../components/icons'
import {
  Sidebar,
  SidebarButton,
  Menu,
  Layout,
  Content,
  Container,
  NavItem,
  View,
} from '../components/shell'
import NotFoundPage from '../pages/404'
import { SectionHeading, DataHeading } from '../components'
import OnboardingApp from './onboarding'
import PartnersApp from './partners'
import ProfileApp from './profile-app'
import SettlementsApp from './settlements-app'

function App() {
  const token = useAuthToken()
  const { claims } = useAuthSession()

  const producerApi = React.useMemo(
    () =>
      ky.extend({
        prefixUrl: process.env.GATSBY__PRODUCER_API_URL,
        hooks: {
          beforeRequest: [
            (request) => {
              request.headers.set('Authorization', `Bearer ${token}`)
            },
          ],
        },
      }),
    [token]
  )

  const [current, send] = useMachine(
    appMachine.withContext({
      producerId: claims.producerId,
      producerApi,
    })
  )

  const producer = current.context.producer

  return (
    <Layout>
      <BrowserView>
        <Sidebar>
          <Box>
            <Header current={current} producer={producer} />
          </Box>
          <Box sx={{ marginTop: 5, marginBottom: 4, flex: 1 }}>
            <Navigation current={current} producer={producer} />
          </Box>
          <Box>
            <Footer />
          </Box>
        </Sidebar>
      </BrowserView>
      <MobileView>
        <Menu>
          <Navigation current={current} producer={producer} />
        </Menu>
      </MobileView>
      <Content>
        {current.matches('fetching') && (
          <Flex my={5} sx={{ justifyContent: 'center' }}>
            <Spinner color="primary" />
          </Flex>
        )}
        {current.matches('failure') && (
          <View>
            <Container>
              <FailureNotice onRetry={() => send('LOAD')} />
            </Container>
          </View>
        )}
        {current.matches('loaded') && (
          <Router basepath="app">
            <ProfileApp
              path="/account/*"
              producer={producer}
              producerApi={producerApi}
              onUpdate={(data) => send('UPDATE', { data })}
            />
            <SettlementsApp
              path="/settlements/*"
              producer={producer}
              producerApi={producerApi}
            />
            <PartnersApp
              path="/partners/*"
              producer={producer}
              producerApi={producerApi}
            />
            <ProcedurePage path="/procedures/" producer={producer} />
            <MarketingPage path="/marketing/" producer={producer} />
            <OnboardingApp path="/onboarding/*" />
            <GetStartedPage
              path="/"
              producer={producer}
              producerApi={producerApi}
            />
            <NotFoundPage default producer={producer} />
          </Router>
        )}
      </Content>
    </Layout>
  )
}

function Navigation({ producer, current }) {
  return (
    <>
      <NavItem>
        <RouterLink to="/app/">Willkommen</RouterLink>
      </NavItem>
      <NavItem>
        <RouterLink to="/app/account">Partnerprofil</RouterLink>
      </NavItem>
      <NavItem>
        <RouterLink to="/app/marketing">Marketing</RouterLink>
      </NavItem>
      <NavItem>
        <RouterLink to="/app/procedures">So funktioniert’s</RouterLink>
      </NavItem>
      {current.matches('loaded') && producer.type !== 'Händler' ? (
        <Box sx={{ mt: 4 }}>
          <DataHeading>Partner</DataHeading>
          <NavItem>
            <RouterLink to="/app/partners/">Deine Partner</RouterLink>
          </NavItem>
          <NavItem>
            <RouterLink to="/app/onboarding/">Partner anlegen</RouterLink>
          </NavItem>
        </Box>
      ) : null}
      {current.matches('loaded') && producer.settlementRecipient ? (
        <Box sx={{ mt: 4 }}>
          <DataHeading>Abrechnung</DataHeading>
          <NavItem>
            <RouterLink to="/app/settlements/">Abrechnungen</RouterLink>
          </NavItem>
        </Box>
      ) : null}
    </>
  )
}

function Header({ current, producer }) {
  return (
    <>
      <GatsbyLink to="/app">
        <StaticImage
          layout="constrained"
          placeholder="tracedSVG"
          width={48}
          alt="Karl"
          src="../images/icon-round.png"
        />
      </GatsbyLink>
      <Text
        as="p"
        variant="smallCaps"
        sx={{
          mt: 3,
          mb: 1,
          color: 'muted',
          fontWeight: 'bold',
          fontSize: 1,
          lineHeight: 1,
        }}
      >
        Partner{producer?.type ? ` (${producer.type})` : null}
      </Text>
      <Heading
        color="primary"
        sx={{ wordBreak: 'break-all', fontSize: 2, fontFamily: 'body' }}
      >
        {current.matches('fetching') && 'lädt …'}
        {current.matches('loaded') && (producer.name || producer.company.name)}
      </Heading>
    </>
  )
}

function Footer() {
  const auth = useAuth()
  const claims = auth.session.claims

  return (
    <>
      <Text as="p" color="muted">
        Angemeldet als
      </Text>
      <Text as="p" sx={{ mb: 4 }}>
        {claims.email}
      </Text>
      <Button onClick={auth.logout} variant="secondary">
        Abmelden
        <Logout16 />
      </Button>
      <Box sx={{ mt: 3 }}>
        <Link as={GatsbyLink} target="_blank" to="/impressum">
          Impressum
        </Link>
      </Box>
    </>
  )
}

const SupportBox = () => {
  return (
    <>
      <DataHeading>Dein Ansprechpartner</DataHeading>
      <Text as="p">Bei Fragen melde Dich gerne bei Nico.</Text>
      <Box sx={{ marginTop: 2, width: 'max-content' }}>
        <Flex
          sx={{
            alignItems: 'center',
            img: {
              width: '100%',
              maxWidth: '10em',
              height: 'auto',
              borderRadius: '1000em',
            },
          }}
        >
          <StaticImage
            width={100}
            height={100}
            alt="Nico"
            src="../images/nico-rassar-support.jpg"
            layout="constrained"
            aspectRatio={1}
            placeholder="blurred"
          />
          <Box
            sx={{
              flex: 1,
              borderLeft: '1px solid',
              borderColor: 'primaries.2',
              paddingLeft: 3,
              marginLeft: 3,
            }}
          >
            <Text as="p" sx={{ fontWeight: 'bold' }}>
              Nico Rassar
            </Text>
            <Text as="p">
              <Link href="mailto:support@engimono.io">support@engimono.io</Link>
            </Text>
            <Text as="p">+43.676.9372999</Text>
          </Box>
        </Flex>
      </Box>
    </>
  )
}

const KarlCard = ({ active }) => {
  return (
    <>
      <Box
        sx={{
          opacity: active ? 1 : 0.3,
          filter: active ? null : 'grayscale(100%)',
        }}
      >
        <StaticImage
          alt="Karl"
          width={80}
          src="../images/karl-icon.png"
          imgStyle={{ borderRadius: '4px' }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Text as="p" sx={{ fontWeight: 'bold' }}>
          Karl Fahrradversicherung
        </Text>
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://karlbikes.com"
        >
          https://karlbikes.com
        </Link>
      </Box>
    </>
  )
}

const LouiseCard = ({ active }) => {
  return (
    <>
      <Box
        sx={{
          opacity: active ? 1 : 0.3,
          filter: active ? null : 'grayscale(100%)',
        }}
      >
        <StaticImage
          alt="Louise"
          width={80}
          src="../images/louise-icon.png"
          imgStyle={{ borderRadius: '4px' }}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Text as="p" sx={{ fontWeight: 'bold' }}>
          Louise Schmuckversicherung
        </Text>
        <Link
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.louise.insure"
        >
          https://www.louise.insure
        </Link>
      </Box>
    </>
  )
}

const GetStartedPage = ({ producer, producerApi }) => {
  const { products } = producer
  const noInactiveProducts =
    products.includes('Karl') && products.includes('Louise')

  return (
    <View>
      <Container>
        <Box sx={{ ':not(:empty)': { marginBottom: 2 } }}>
          <SidebarButton />
        </Box>
        <Heading as="h1" sx={{ mb: 5 }}>
          Willkommen {producer.contact.firstname}!
        </Heading>
        <Text sx={{ fontSize: [1, 2] }}>
          <Text sx={{ maxWidth: '48ch' }}>
            <Text as="span" sx={{ fontWeight: 'bold' }}>
              Du fragst Dich was das hier ist?
            </Text>{' '}
            Auf diesen Seiten kannst Du Einstellungen zu Deinem Partnerkonto
            einsehen und ändern, Marketing Material anfordern, oder Dich
            informieren wie alles funktioniert. Bei Fragen kannst Du Dich
            jederzeit bei uns melden.
          </Text>
        </Text>
      </Container>
      <Container>
        <SectionHeading>Dashboard</SectionHeading>
        <Box as="section">
          <Dashboard producer={producer} producerApi={producerApi} />
        </Box>
      </Container>
      <Container>
        <Box as="section">
          <SectionHeading>Deine Produkte</SectionHeading>
          <Box>
            <Grid columns={[1, 2]} gap={5}>
              <Box>
                <KarlCard active={products.includes('Karl')} />
              </Box>
              <Box>
                <LouiseCard active={products.includes('Louise')} />
              </Box>
            </Grid>
            {noInactiveProducts ? null : (
              <Message variant="primary" sx={{ mt: 5 }}>
                <Text as="span" sx={{ fontWeight: 'bold' }}>
                  Hinweis:{' '}
                </Text>
                Die ausgegrauten Produkte werden von Dir noch nicht beworben.
                Bei Interesse kannst Du uns kontaktieren.
              </Message>
            )}
          </Box>
        </Box>
      </Container>
      <Container>
        <SupportBox />
      </Container>
    </View>
  )
}

const ProcedurePage = ({ producer }) => {
  return (
    <View>
      <Container>
        <Box sx={{ ':not(:empty)': { marginBottom: 2 } }}>
          <SidebarButton />
        </Box>
        <Heading as="h1" sx={{ mb: 3 }}>
          So funktioniert’s
        </Heading>
        <Text as="h3" sx={{ fontWeight: 'bold', mb: 1 }}>
          Inhaltverzeichniss
        </Text>
        <Box as="ul" sx={{ p: 0 }}>
          <Text as="li">
            <Link href="#setup">Geräte verbinden</Link>
          </Text>
          <Text as="li">
            <Link href="#procurement">Abschluss</Link>
          </Text>
          <Text as="li">
            <Link href="#claim">Schaden</Link>
          </Text>
        </Box>
      </Container>
      <Container>
        <section id="setup">
          <SectionHeading>Geräte verbinden</SectionHeading>
          <Box sx={{ mb: 4 }}>
            <Text>
              Damit die von Dir vermittelten Polizzen auch Dir zugeordnet werden
              können, musst Du Deine Geräte bzw. Deinen Browser mit Deiner Id
              verbinden. Öffne diesen Link auf den Geräten auf denen Du unsere
              Produkte abschließen willst.
            </Text>
            <Box sx={{ mt: 4 }}>
              {producer.products.map((productName) => {
                const proposalWebsites = {
                  karl: 'https://karlbikes.com/producer/associate/?ref=p',
                  louise: 'https://antrag.louise.insure/partner/?ref=p',
                }
                const setupLink = `${
                  proposalWebsites[productName.toLowerCase()]
                }${producer.referrerToken}`

                return (
                  <Flex
                    as="a"
                    target="_blank"
                    key={productName}
                    href={setupLink}
                    sx={{
                      '&:not(:first-child)': { mt: 2 },
                      borderRadius: '4px',
                      overflow: 'hidden',
                      bg: 'gray.0',
                      alignItems: 'center',
                      textDecoration: 'none',
                      color: 'inherit',
                      '&:hover': {
                        bg: 'gray.1',
                      },
                    }}
                  >
                    <Flex sx={{ svg: { width: '2em', height: '2em' } }}>
                      {productName === 'Louise' && <ProductIcons.LouiseIcon />}
                      {productName === 'Karl' && <ProductIcons.KarlIcon />}
                    </Flex>
                    <Box
                      sx={{
                        px: 3,
                        py: 1,
                      }}
                    >
                      <Text
                        sx={{
                          fontWeight: 'bold',
                        }}
                        key={productName}
                      >
                        {productName} mit diesem Browser verbinden
                      </Text>
                    </Box>
                  </Flex>
                )
              })}
            </Box>
          </Box>
        </section>
        <Divider />
        <section id="procurement">
          <SectionHeading>Abschluss</SectionHeading>
          <p>
            Dein Kunde schließt auf der jeweiligen Seite des Produktes ab. Wenn
            der Abschluss über Deinen{' '}
            <Link as={RouterLink} to="/app/marketing/#referrer_link">
              Referrer Link
            </Link>{' '}
            gemacht wird, oder Du Dein verbundenes Gerät verwendest, wird der
            Abschluss automatisch Dir zugeordnet. Die Bediendung unserer Seiten
            ist sehr leicht verständlich und sollte es dem Kunden ermöglichen
            eigenständig einen Abschluss zu tätigen. Du kannst natürlich den
            Kunden auch dabei unterstützen.
          </p>
        </section>
        <Divider />
        <section id="claim">
          <SectionHeading>Schadenfall</SectionHeading>
          <p>
            Unser gemeinsamer Kunde meldet den Schaden über die Webseite des
            jeweiligen Produktes. Der Schaden wird dann so schnell wie möglich
            von uns bearbeitet. Das dauert bei uns in den meisten Fällen nicht
            länger als ein paar Stunden nach Eingang der Schadensmeldung.
          </p>
        </section>
      </Container>
    </View>
  )
}

const MarketingPage = ({ producer }) => {
  return (
    <View>
      <Container>
        <Box sx={{ ':not(:empty)': { marginBottom: 2 } }}>
          <SidebarButton />
        </Box>
        <Heading as="h1">Marketing</Heading>
      </Container>
      <Container>
        <Box as="section" id="referrer_link" sx={{ mb: 3 }}>
          <SectionHeading>Referrer Link</SectionHeading>
          <ReferrerLinks producer={producer} />
        </Box>
        <Divider />
        <Box as="section" sx={{ my: 4 }}>
          <SectionHeading>Promotionmaterial</SectionHeading>
          <Text as="p" sx={{ mb: 3 }}>
            Wenn Du unsere Flyer und Aufsteller benutzen möchtest, kannst Du uns
            einfach ein kurzes Mail mit den gewünschten Produkten und der
            Stückzahl schreiben. Wir schicken dir das Material dann per Post zu
            oder kommen persönlich bei Dir vorbei.
          </Text>
          <Heading as="h3" sx={{ mb: 2, fontFamily: 'body' }}>
            Druck Medien
          </Heading>
          <Box as="ul" sx={{ padding: 0, mb: 3 }}>
            <Text as="li">Kunden Flyer</Text>
            <Text as="li">QR-Code Aufsteller</Text>
            <Text as="li">Aufhänger</Text>
            <Text as="li">Aufkleber</Text>
          </Box>
          <Heading as="h3" sx={{ mb: 2, fontFamily: 'body' }}>
            Online Medien
          </Heading>
          <Box as="ul" sx={{ padding: 0 }}>
            <Text as="li">Banner (verschiedene Größen)</Text>
          </Box>
        </Box>
      </Container>
    </View>
  )
}

const assignProducer = assign({
  producer: (_context, event) => event.data,
})

const assignError = assign({
  error: (_context, event) => event.data,
})

async function getProducer(context) {
  const { producerId, producerApi } = context
  const response = await producerApi.get(`producers/${producerId}/`).json()

  return response
}

const appMachine = Machine(
  {
    id: 'app',
    initial: 'fetching',
    on: {
      LOAD: { target: 'fetching' },
    },
    states: {
      fetching: {
        invoke: {
          src: 'getProducer',
          onDone: {
            target: 'loaded',
            actions: 'assignProducer',
          },
          onError: {
            target: 'failure',
            actions: 'assignError',
          },
        },
      },
      loaded: {
        on: {
          UPDATE: {
            actions: 'assignProducer',
          },
        },
      },
      failure: {},
    },
  },
  {
    services: {
      getProducer,
    },
    actions: {
      assignProducer,
      assignError,
    },
  }
)

export default App
