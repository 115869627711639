import React from "react";
import { Heading, Text } from "theme-ui";
import { Meta } from "../components/head";
import { Layout } from "../components/shell";

const NotFoundPage = () => (
  <Layout>
    <Meta title="Seite nicht gefunden" />
    <Heading sx={{ fontSize: 5 }}>Seite nicht gefunden</Heading>
    <Text>Leider konnte die von Dir gewollte Seite nicht gefunden worden.</Text>
  </Layout>
);

export default NotFoundPage;
