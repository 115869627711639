import * as yup from "yup";

/* eslint-disable */
yup.setLocale({
  string: {
    min: "Muss länger sein als ${min} Zeichen",
    max: "Muss kürzer oder gleich lang sein als ${max} Zeichen",
    email: "Muss eine valide Email Adresse sein",
    matches: "Muss aus Nummern bestehen",
    length: "Muss genau ${length} Zeichen lang sein",
  },
  mixed: {
    default: "Das ist leider keine valide Eingabe",
    required: "Das ist ein benötigtes Feld",
  },
});
/* eslint-enable */

export const validator = yup;

const COUNTRIES = {
  AT: "Österreich",
  DE: "Deutschland",
  CH: "Schweiz",
};

export function formatPostalAddress(
  addressObj,
  { seperator = ", ", locale = "de-at" } = {},
) {
  if (locale !== "de-at") {
    throw new Error(
      `The postal addres format for ${locale} has not yet been implemented yet`,
    );
  }
  const {
    apartment,
    streetNumber,
    route,
    locality,
    postalcode,
    postalCode,
    country,
  } = addressObj;
  const firstLine = [route, streetNumber, apartment]
    .filter((x) => x)
    .map((x) => x.trim())
    .join(" ");
  const secondLine = [postalcode || postalCode, locality]
    .filter((x) => x)
    .map((x) => x.trim())
    .join(" ");
  const thirdLine = COUNTRIES[country];

  return [firstLine, secondLine, thirdLine].join(seperator);
}
