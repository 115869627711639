import { useAuthSession } from "@msc-insure/use-auth";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Heading,
  Input,
  Label,
  Link,
  Message,
  Select,
  Spinner,
  Text,
} from "theme-ui";
import { Save16 } from "@carbon/icons-react"
import { Field, Fieldset } from "../../components"
import { Link as RouterLink } from "@reach/router";
import { useService } from "@xstate/react";
import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { schemaOnSubmit } from "./form-model";

function OnboardingForm({ service, draftId, initialValues }) {
  const { claims } = useAuthSession();

  const {
    formState,
    register,
    handleSubmit,
    getValues,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schemaOnSubmit),
    defaultValues: {
      parentProducerId: claims.producerId,
      ...initialValues,
      type: initialValues.type || "Händler",
      products: initialValues.products || [],
    },
  });

  const { errors } = formState

  const watchedFields = watch(["contact.email", "company.name"], initialValues);

  const setSameEmail = React.useCallback(() => {
    const email = getValues("contact.email");
    setValue("email", email);
  }, [getValues, setValue]);

  const [state, send] = useService(service);

  const saveDraft = React.useCallback(
    () => {
      send("UPDATE", {
        draftId,
        data: getValues(),
      })
    },
    [send, draftId, getValues],
  );

  const finalizeDraft = React.useCallback(
    (values) =>
      send("FINALIZE", {
        draftId,
        data: values,
      }),
    [send, draftId],
  );

  return (
    <form onSubmit={handleSubmit(finalizeDraft)}>
      <FormHeader
        companyName={watchedFields[1] || "Partner"}
        onSave={saveDraft}
        isSaving={state.matches("update")}
      />
      <Field sx={{ mb: 3 }}>
        <Label htmlFor="type">Rolle</Label>
        <Select
          aria-invalid={!!errors?.type}
          {...register("type")}
        >
          <option value="Makler">Makler</option>
          <option value="Händler">Händler</option>
          <option value="Tippgeber">Tippgeber</option>
        </Select>
        {errors?.type && <Field.error>{errors.type.message}</Field.error>}
      </Field>
      <Field sx={{ mb: 3 }}>
        <Label>Produkte</Label>
        <Label variant="labelCheckbox" sx={{ mb: 1, bg: "gray.0", p: 2 }}>
          <Checkbox value="Louise" {...register("products")} />
          Louise
        </Label>
        <Label variant="labelCheckbox" sx={{ bg: "gray.0", p: 2 }}>
          <Checkbox value="Karl" {...register("products")} />
          Karl
        </Label>
        {errors?.type && <Field.error>{errors.type.message}</Field.error>}
      </Field>
      <Field
        sx={{ mb: 5, display: claims.role !== "admin" ? "none" : "block" }}
      >
        <Label htmlFor="parentProducerId">Parent Partner Id</Label>
        <Input
          readonly={claims.role !== "admin"}
          aria-invalid={!!errors?.parentProducerId}
          {...register("parentProducerId")}
        />
        {errors?.producerDraftId && (
          <Field.error>{errors.producerDraftId.message}</Field.error>
        )}
      </Field>
      <Fieldset>
        <Fieldset.legend>1. Unternehmen</Fieldset.legend>
        <Text as="p" mb={3} sx={{ maxWidth: "60ch" }}>
          Bitte gib hier die Firmenadresse an.
        </Text>
        <Grid columns={[null, 8, 10]} gap={3}>
          <Field sx={{ gridColumn: [null, "span 6"] }}>
            <Label htmlFor="company.name">Firma</Label>
            <Input
              data-prefilled={initialValues?.company?.name
                && formState?.touched?.company?.name}
              aria-invalid={!!errors?.company?.name}
              {...register("company.name")}
            />
            {errors?.company?.name && (
              <Field.error>{errors.company.name.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: [null, "1 / 4 span"] }}>
            <Field.label>Straße</Field.label>
            <Input
              aria-invalid={!!errors?.company?.address?.route}
              {...register("company.address.route")}
            />
          </Field>
          <Field sx={{ gridColumn: [null, "5 / 2 span"] }}>
            <Field.label>Hausnummer</Field.label>
            <Input
              name="company.address.streetNumber"
              aria-invalid={!!errors?.company?.address?.streetNumber}
              {...register("company.address.streetNumber")}
            />
          </Field>
          <Field sx={{ gridColumn: [null, "7 / 2 span"] }}>
            <Field.label>Tür, Stiege, etc.</Field.label>
            <Input
              aria-invalid={!!errors?.company?.address?.apartment}
              {...register("company.address.apartment")}
            />
          </Field>
          <Field sx={{ gridColumn: [null, "1 / 2 span"] }}>
            <Field.label>Postleitzahl</Field.label>
            <Input
              aria-invalid={!!errors?.company?.address?.postalCode}
              {...register("company.address.postalCode")}
            />
          </Field>
          <Field sx={{ gridColumn: [null, "3 / 3 span"] }}>
            <Field.label>Ort</Field.label>
            <Input
              name="company.address.locality"
              aria-invalid={!!errors?.company?.address?.locality}
              {...register("company.address.locality")}
            />
          </Field>
          <Field sx={{ gridColumn: [null, "6 / 3 span"] }}>
            <Field.label>Land</Field.label>
            <Select
              aria-invalid={!!errors?.company?.address?.country}
              {...register("company.address.country")}
            >
              <option value="AT">Österreich</option>
              <option value="DE">Deutschland</option>
              <option value="CH">Schweiz</option>
              <option value="IT">Italien</option>
            </Select>
          </Field>
        </Grid>
      </Fieldset>
      <Fieldset>
        <Fieldset.legend>2. Kontaktperson</Fieldset.legend>
        <Text as="p" sx={{ maxWidth: "60ch" }} mb={3}>
          Wer wird unser Ansprechpartner sein?
        </Text>
        <Grid columns={[1, 12]} gap={3}>
          <Field sx={{ gridColumn: ["span 1", "span 4"] }}>
            <Label htmlFor="contact.firstname">Vorname</Label>
            <Input
              aria-invalid={!!errors?.contact?.firstname}
              {...register("contact.firstname")}
            />
          </Field>
          <Field sx={{ gridColumn: ["span 1", "span 4"] }}>
            <Label htmlFor="contact.lastname">Nachname</Label>
            <Input
              aria-invalid={!!errors?.contact?.lastname}
              {...register("contact.lastname")}
            />
          </Field>
          <Field sx={{ gridColumn: ["span 1", "span 5"] }}>
            <Label htmlFor="contact.email">Email</Label>
            <Input
              type="email"
              aria-invalid={!!errors?.contact?.email}
              {...register("contact.email")}
            />
          </Field>
          <Field sx={{ gridColumn: ["span 1", "span 3"] }}>
            <Label htmlFor="contact.phone">Telefon</Label>
            <Input
              type="phone"
              aria-invalid={!!errors?.contact?.phone}
              {...register("contact.phone")}
            />
          </Field>
          <Field sx={{ gridColumn: ["span 1", "span 3"] }}>
            <Label htmlFor="contact.role">Rolle (optional)</Label>
            <Input
              placeholder="Geschäftsführer"
              aria-invalid={!!errors?.contact?.phone}
              {...register("contact.role")}
            />
          </Field>
        </Grid>
      </Fieldset>
      <Fieldset>
        <Fieldset.legend>3. Bankkonto</Fieldset.legend>
        <Text as="p" sx={{ maxWidth: "60ch" }} mb={3}>
          Wir benötigen ein Bankkonto um im Fall eines Schadens den Betrag an Dich zu überweisen.
        </Text>
        <Grid columns={[1, 12]} gap={3}>
          <Field sx={{ gridColumn: ["span 1", "span 6"] }}>
            <Label htmlFor="bank.iban" >
              IBAN
            </Label>
            <Input {...register("bank.iban")} />
          </Field>
        </Grid>
      </Fieldset>
      <Fieldset>
        <Fieldset.legend>4. Login</Fieldset.legend>
        <Text as="p" sx={{ maxWidth: "60ch" }} mb={3}>
          Mit welcher Email Adresse wirst Du dich bei uns im System anmelden.
        </Text>
        <Grid columns={[1, 12]} gap={3}>
          <Field sx={{ gridColumn: ["span 1", "span 6"] }}>
            <Label htmlFor="email">Email</Label>
            <Input
              id="email"
              name="email"
              aria-invalid={!!errors.email}
              {...register("email")}
            />
            {!!watchedFields[0] && (
              <Box mt={3}>
                <Button
                  type="button"
                  sx={{ fontSize: 0 }}
                  variant="outline"
                  onClick={setSameEmail}
                >
                  Kontakt Email übernehmen
                </Button>
              </Box>
            )}
          </Field>
        </Grid>
        <Message variant="primary" mt={4} sx={{ maxWidth: "60ch" }}>
          An dieser Email Adresse werden wir Dich als Partner erkennen.
        </Message>
      </Fieldset>
      <Fieldset>
        <Fieldset.legend>5. Rechtliches</Fieldset.legend>
        <Label variant="labelCheckbox">
          <Box sx={{ flex: "none" }}>
            <Checkbox {...register("consent")} />
          </Box>
          <Box sx={{ maxWidth: "60ch" }}>
            <Text as="p" sx={{ maxWidth: "40ch" }}>
              Wenn Du hier Dein Häckchen setzt dann nimmst Du folgenden Punkt zur Kenntniss.
            </Text>
            <Box
              as="ul"
              variant="lists.primary"
              pl={0}
              sx={{ fontWeight: "body" }}
            >
              <li>
                Wir verlassen uns auf die Vollständigkeit und Richtigkeit der von Dir zur Verfügung gestellten Daten und
                Informationen und Du stimmst den Allgemeinen Geschäftsbedingungen zu.
              </li>
            </Box>
          </Box>
        </Label>
      </Fieldset>
      <Fieldset
        sx={{ mb: 5, display: claims.role !== "admin" ? "none" : "block" }}
      >
        <Fieldset.legend>X. Optionen</Fieldset.legend>
        <Field>
          <Label variant="labelCheckbox" sx={{ bg: "gray.0", p: 2 }}>
            <Checkbox
              readonly={claims.role !== "admin"}
              id="options.skipLetter"
              name="options.skipLetter"
              {...register("options.skipLetter")}
            />
            Skip Welcome Letter
          </Label>
          {errors?.options?.skipLetter && (
            <Field.error>{errors.options.skipLetter.message}</Field.error>
          )}
        </Field>
      </Fieldset>
      <Divider />
      <Flex mt={5} sx={{ alignItems: "center" }}>
        <Button type="submit" sx={{ fontSize: [2, 2, 3] }}>
          Fertigstellen
        </Button>
        {state.matches("finalize") && <Spinner ml={3} size={24} />}
      </Flex>
    </form>
  );
}

function FormHeader({ companyName, onSave, isSaving }) {
  return (
    <Flex
      sx={{
        mb: 5,
        pb: 5,
        borderBottom: "4px solid",
        borderColor: "gray.1",
        alignItems: "flex-end",
      }}
    >
      <Box>
        <Heading sx={{ color: "primary", mb: 3, fontSize: 4, fontFamily: "body" }}>
          {companyName}
        </Heading>
        <Flex sx={{ alignItems: "center" }}>
          <Button type="button" sx={{ svg: { marginLeft: 2 } }} onClick={onSave} variant="outline">
            Entwurf Speichern
            <Save16 />
          </Button>
          {isSaving && <Spinner size={24} ml={2} />}
        </Flex>
      </Box>
      <Box ml="auto">
        <Link as={RouterLink} to="/app/onboarding/">
          Zurück zur Übersicht
        </Link>
      </Box>
    </Flex>
  );
}

export default OnboardingForm;
