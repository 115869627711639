import { Box, Heading, Link, Message } from "theme-ui";
import { useService } from "@xstate/react";
import { Link as GatsbyLink } from "gatsby";
import React from "react";

function SuccessPage({ service }) {
  const [current] = useService(service);
  const finalized = current.context.finalized[0];

  return (
    <>
      <Heading mb={5}>
        <Box mb={3} sx={{ fontSize: 7 }}>
          <span role="img" aria-label="celebrate emoji">
            🎉
          </span>
        </Box>{" "}
        Wunderbar! „{finalized.company.name}” ist jetzt ein Partner.
      </Heading>
      <Message mt={6} variant="primary" sx={{ maxWidth: "measure" }}>
        Wir haben eine Email an {finalized.email} mit einer Bestätigung geschickt.{" "}
      </Message>
      <Box mt={5}>
        <Link as={GatsbyLink} to="/app/onboarding/">
          Zurück zur Übersicht
        </Link>
      </Box>
    </>
  );
}

export default SuccessPage;
