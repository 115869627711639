import { Link as RouterLink, Router } from "@reach/router";
import PropTypes from "prop-types";
import React from "react";
import { useQuery } from "react-query";
import { Box, Card, Divider, Flex, Heading, Link, Spinner, Text } from "theme-ui";
import { Dashboard } from "../components/dashboard";
import { ReferrerLinks } from "../components/referrer-link";
import { SidebarButton, Container, View } from "../components/shell";
import NotFoundPage from "../pages/404";
import { SectionHeading } from "../components";
import { formatPostalAddress } from "../utils";

export default function PartnersApp({ producer, producerApi }) {
  return (
    <Router>
      <ShowPartner path="/:id" producer={producer} producerApi={producerApi} />
      <ListPartners path="/" producer={producer} producerApi={producerApi} />
      <NotFoundPage default />
    </Router>
  );
}

PartnersApp.propTypes = {
  producer: PropTypes.object.isRequired,
  producerApi: PropTypes.func.isRequired,
};

const ListPartners = ({ producerApi, producer }) => {
  const { isLoading, error, data } = useQuery(["partners"], async () => {
    const data = await producerApi
      .get(`producers/${producer.producerId}/partners`)
      .json();

    return data;
  });

  return (
    <View>
      <Container>
        <Box sx={{ ":not(:empty)": { marginBottom: 2 } }}>
          <SidebarButton />
        </Box>
        <Heading as="h1">Deine Partner</Heading>
        <Text as="p">Anzahl: {isLoading ? "lädt …" : data.length}</Text>
      </Container>
      <Container>
        {isLoading && (
          <Flex sx={{ justifyContent: "center" }}>
            <Box>
              <Spinner size={24} />
            </Box>
          </Flex>
        )}
        {!error && data?.length > 0
          ? data.map((producer) => (
            <React.Fragment key={producer.producerId}>
              <ProducerItem producer={producer} />
              <Divider my={[2, 2, 2]} />
            </React.Fragment>
          ))
          : null}
        {!error && data?.length === 0 && (
          <Text as="p" t variant="bold" color="muted">
            Du hast noch keine Partner.
          </Text>
        )}
      </Container>
      <Container></Container>
    </View>
  );
};

const ShowPartner = ({ producerApi, producer, id }) => {
  const { isLoading, error, data } = useQuery(
    ["partners", producer.producerId, id],
    async () => {
      const data = await producerApi
        .get(`producers/${producer.producerId}/partners/${id}`)
        .json();

      return data;
    },
  );

  return (
    <View>
      {isLoading && (
        <Container>
          <Flex sx={{ justifyContent: "center" }}>
            <Box>
              <Spinner size={24} />
            </Box>
          </Flex>
        </Container>
      )}
      {!error && data
        ? (
          <>
            <Container>
              <Text
                as="p"
                sx={{
                  fontSize: 2,
                  fontFamily: "Alegreya Sans SC",
                  lineHeight: 1,
                  marginBottom: 2,
                  textTransform: "lowercase",
                }}
              >
                Dein Partner
              </Text>
              <Heading as="h1">{data.name || data.company.name}</Heading>
              <Pill>{data.type}</Pill>
            </Container>
            <Container>
              <SectionHeading>Dashboard</SectionHeading>
              <Dashboard
                partnerId={id}
                producer={producer}
                producerApi={producerApi}
              />
            </Container>
            <Container>
              <Box>
                <SectionHeading>Kontakt</SectionHeading>
                <Text as="p">
                  {data.contact.firstname} {data.contact.lastname}
                </Text>
                <Text as="p">
                  <Link href={`tel:${data.contact.telephone}`}>
                    {data.contact.telephone}
                  </Link>
                </Text>
                <Text as="p">
                  <Link href={`mailto:${data.contact.email}`}>
                    {data.contact.email}
                  </Link>
                </Text>
                <Text as="p">{formatPostalAddress(data.company.address)}</Text>
                {data.website
                  ? (
                    <Text>
                      <Link href={data.website}>{data.website}</Link>
                    </Text>
                  )
                  : null}
              </Box>
              <Divider />
              <Box>
                <SectionHeading>Partner Link</SectionHeading>
                <Card>
                  <ReferrerLinks producer={data} />
                </Card>
              </Box>
            </Container>
          </>
        )
        : null}
      <Container></Container>
    </View>
  );
};

function ProducerItem({ producer }) {
  return (
    <>
      <Flex sx={{ alignItems: "center" }}>
        <Link
          to={`./${producer.producerId}`}
          as={RouterLink}
          sx={{ color: "text", textDecoration: "none" }}
        >
          <Text
            as="p"
            sx={{
              variant: "text.smallCaps",
              fontWeight: 500,
              fontSize: 2,
              lineHeight: 1,
            }}
          >
            {producer.company.name}
            {producer.name ? ` (${producer.name})` : null}
          </Text>
        </Link>
        <Pill>{producer.type}</Pill>
      </Flex>
      <Text as="p" sx={{ marginTop: 1 }} color="muted">
        Abgeschlossen am {new Intl.DateTimeFormat("de").format(new Date(producer.createdAt))}
      </Text>
    </>
  );
}

function Pill(props) {
  return (
    <Text
      as="p"
      {...props}
      sx={{
        marginLeft: "auto",
        fontFamily: "monospace",
        fontWeight: "bold",
        lineHeight: 1,
        padding: "4px",
        paddingX: "4px",
        color: "primary",
        display: "inline-block",
        fontSize: 11,
        bg: "primaries.1",
        borderRadius: 1,
      }}
    />
  );
}
