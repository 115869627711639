import * as yup from 'yup'

yup.setLocale({
  mixed: {
    default: 'field_invalid',
    required: 'field_required',
  },
})

export const schemaOnSave = yup.object().shape({
  type: yup.string(),
  parentProducerId: yup.string(),
  products: yup.array().of(yup.string().oneOf(['Karl', 'Louise'])),
  company: yup.object().shape({
    name: yup.string().required(),
    address: yup.object().shape({
      route: yup.string().nullable(),
      streetNumber: yup.string().nullable(),
      apartment: yup.string().nullable(),
      locality: yup.string().nullable(),
      postalCode: yup.string().nullable(),
      country: yup.string().nullable(),
    }),
  }),
  contact: yup.object().shape({
    firstname: yup.string().nullable(),
    lastname: yup.string().nullable(),
    role: yup.string().nullable(),
    email: yup.string().nullable(),
    phone: yup.string().nullable(),
  }),
  bank: yup.object().shape({
    iban: yup.string().nullable(),
  }),
  email: yup.string().nullable(),
  consent: yup.boolean().nullable(),
})

export const schemaOnSubmit = yup.object().shape({
  type: yup.string().required(),
  parentProducerId: yup.string().required(),
  products: yup
    .array()
    .of(yup.string().oneOf(['Karl', 'Louise']))
    .min(1)
    .required(),
  company: yup.object().shape({
    name: yup.string().required(),
    address: yup.object().shape({
      route: yup.string().required(),
      streetNumber: yup.string().required(),
      apartment: yup.string().nullable(),
      locality: yup.string().required(),
      postalCode: yup.string().required(),
      country: yup.string().required(),
    }),
  }),
  contact: yup
    .object()
    .shape({
      firstname: yup.string().required(),
      lastname: yup.string().required(),
      role: yup.string().nullable(),
      email: yup.string().required().trim(),
      phone: yup.string().required(),
    })
    .required(),
  bank: yup.object().shape({
    iban: yup.string().required(),
  }),
  email: yup.string().required().trim(),
  consent: yup.boolean().oneOf([true]),
  options: yup
    .object()
    .shape({
      skipLetter: yup.boolean(),
    })
    .required(),
})
