import {
  Box,
  Button,
  Divider,
  Flex,
  Grid,
  Heading,
  Input,
  Spinner,
} from 'theme-ui'
import { Datum, DatumLabel, DatumValue, Field, Fieldset } from '../components'
import { Link as RouterLink, Router } from '@reach/router'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { SidebarButton, Container, View } from '../components/shell'
import NotFoundPage from '../pages/404'
import { formatPostalAddress, validator } from '../utils'

export default function ProfileApp({ producer, producerApi, onUpdate }) {
  return (
    <Router>
      <ShowProfile path="/" producer={producer} producerApi={producerApi} />
      <EditProfile
        path="/edit"
        producer={producer}
        producerApi={producerApi}
        onUpdate={onUpdate}
      />
      <NotFoundPage default />
    </Router>
  )
}

function ShowProfile({ producer }) {
  return (
    <View>
      <Container>
        <Box sx={{ ':not(:empty)': { marginBottom: 2 } }}>
          <SidebarButton />
        </Box>
        <Flex>
          <Heading as="h1">Partnerprofil</Heading>
          <Box sx={{ ml: 'auto' }}>
            <Button as={RouterLink} variant="primary" to="./edit">
              Bearbeiten
            </Button>
          </Box>
        </Flex>
      </Container>
      <Container>
        <Box>
          <Box mb={3}>
            <GeneralData {...producer} />
          </Box>
          <Box mb={3}>
            <CompanyData {...producer.company} />
          </Box>
          <Box mb={3}>
            <ContactData {...producer.contact} />
          </Box>
          <Box mb={3}>
            <BankData {...producer.bank} />
          </Box>
        </Box>
      </Container>
    </View>
  )
}

function EditProfile({ producer, producerApi, onUpdate, navigate }) {
  const { isSuccess, isLoading, mutate } = useMutation(async (formData) => {
    const result = await producerApi
      .patch(`producers/${producer.producerId}/`, { json: formData })
      .json()
    onUpdate(result)

    return result
  })

  useEffect(() => {
    if (isSuccess) {
      navigate('/app/account/')
    }
  })

  return (
    <View>
      <Container>
        <Flex>
          <Box>
            <Heading as="h1">Partnerprofil</Heading>
            <Heading variant="styles.h4" color="primary" mt={3}>
              Bearbeiten …
            </Heading>
          </Box>
          <Box sx={{ ml: 'auto' }}>
            <Button as={RouterLink} variant="secondary" to="../">
              Abbrechen
            </Button>
          </Box>
        </Flex>
      </Container>
      <Container>
        <Form
          isSubmitting={isLoading}
          initialValues={producer}
          onSubmit={mutate}
        />
      </Container>
    </View>
  )
}

const schema = validator.object().shape({
  name: validator.string().required(),
  website: validator.string(),
  company: validator.object().shape({
    name: validator.string().required(),
    vatNumber: validator.string(),
    address: validator.object().shape({
      route: validator.string().required(),
      streetNumber: validator.string(),
      apartment: validator.string(),
      postalcode: validator.string().required(),
      locality: validator.string().required(),
      country: validator.string().length(2).required(),
    }),
  }),
  password: validator.string(),
})

const Form = ({ onSubmit, initialValues, isSubmitting }) => {
  const { formState, register, handleSubmit } = useForm({
    validationSchema: schema,
    defaultValues: initialValues || undefined,
  })

  const { errors } = formState

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <Grid mb={3} gap={3}>
        <Field>
          <Field.label htmlFor="name">Anzeigename</Field.label>
          <Input aria-invalid={!!errors.name} {...register('name')} />
          {errors?.name && <Field.error>{errors.name.message}</Field.error>}
        </Field>
        <Field>
          <Field.label htmlFor="website">Website</Field.label>
          <Input
            placeholder="ATU12345678"
            aria-invalid={!!errors.website}
            {...register('website')}
          />
          {errors?.website && (
            <Field.error>{errors.website.message}</Field.error>
          )}
        </Field>
      </Grid>
      <Fieldset variant="secondary">
        <Fieldset.legend variant="secondary">Unternehmen</Fieldset.legend>
        <Grid mb={3} columns={[2, 12]} gap={3}>
          <Field sx={{ gridColumn: ['span 12'] }}>
            <Field.label htmlFor="company.name">Firma</Field.label>
            <Input
              placeholder="Firma GmbH"
              aria-invalid={!!errors?.company?.name}
              {...register('company.name')}
            />
            {errors?.company?.name && (
              <Field.error>{errors.name.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 12'] }}>
            <Field.label htmlFor="company.vatNumber">UID-Nummer</Field.label>
            <Input
              placeholder="ATU12345678"
              type="text"
              aria-invalid={!!errors.vatNumber}
              {...register('company.vatNumber')}
            />
            {errors?.company?.vatNumber && (
              <Field.error>{errors.company.vatNumber.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 12', 'span 6'] }}>
            <Field.label htmlFor="company.address.route">Straße</Field.label>
            <Input
              placeholder="Waaggasse"
              aria-invalid={!!errors?.company?.address.route}
              {...register('company.address.route')}
            />
            {errors?.company?.address?.route && (
              <Field.error>{errors.address.route.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 6', 'span 3'] }}>
            <Field.label htmlFor="company.address.streetNumber">
              Hausnummer
            </Field.label>
            <Input
              placeholder="18"
              id="streetNumber"
              aria-invalid={!!errors?.company?.address?.streetNumber}
              {...register('company.address.streetNumber')}
            />
            {errors?.company?.address?.streetNumber && (
              <Field.error>{errors.address.streetNumber.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 6', 'span 3'] }}>
            <Field.label htmlFor="company.address.apartment">
              Stieg/Tür/etc.
            </Field.label>
            <Input
              aria-invalid={!!errors?.company?.address?.apartment}
              {...register('company.address.apartment')}
            />
            {errors?.company?.address?.apartment && (
              <Field.error>{errors.address.apartment.message}</Field.error>
            )}
          </Field>
        </Grid>
        <Grid mb={3} columns={[2, 12]} gap={3}>
          <Field sx={{ gridColumn: ['span 1', 'span 3'] }}>
            <Field.label htmlFor="company.address.postalcode">
              Postleitzahl
            </Field.label>
            <Input
              placeholder="1040"
              aria-invalid={!!(errors.address && errors.address.postalcode)}
              {...register('company.address.postalcode')}
            />
            {errors.address && errors.address.postalcode && (
              <Field.error>{errors.address.postalcode.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 1', 'span 3'] }}>
            <Field.label htmlFor="company.address.locality">Ort</Field.label>
            <Input
              placeholder="Wien"
              aria-invalid={!!(errors.address && errors.address.locality)}
              {...register('company.address.locality')}
            />
            {errors?.address?.locality && (
              <Field.error>{errors.address.locality.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 2', 'span 6'] }}>
            <Field.label htmlFor="company.address.country">Land</Field.label>
            <Input
              placeholder="AT"
              aria-invalid={!!errors?.address?.country}
              {...register('company.address.country')}
            />
            {errors?.address?.country && (
              <Field.error>{errors.country.message}</Field.error>
            )}
          </Field>
        </Grid>
      </Fieldset>
      <Fieldset variant="secondary">
        <Fieldset.legend variant="secondary">Kontakt</Fieldset.legend>
        <Grid mb={3} columns={[2, 12]} gap={3}>
          <Field sx={{ gridColumn: ['span 2', 'span 6'] }}>
            <Field.label htmlFor="contact.firstname">Vorname</Field.label>
            <Input
              aria-invalid={!!errors?.contact?.firstname}
              {...register('contact.firstname')}
            />
            {errors?.contact?.firstname && (
              <Field.error>{errors.contact.firstname.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 2', 'span 6'] }}>
            <Field.label htmlFor="contact.lastname">Nachname</Field.label>
            <Input
              aria-invalid={!!errors?.contact?.lastname}
              {...register('contact.lastname')}
            />
            {errors?.contact?.lastname && (
              <Field.error>{errors.contact.lastname.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 2', 'span 6'] }}>
            <Field.label htmlFor="contact.email">Email</Field.label>
            <Input
              aria-invalid={!!errors?.contact?.email}
              {...register('contact.email')}
            />
            {errors?.contact?.email && (
              <Field.error>{errors.contact.email.message}</Field.error>
            )}
          </Field>
          <Field sx={{ gridColumn: ['span 2', 'span 6'] }}>
            <Field.label htmlFor="contact.phone">Telefon</Field.label>
            <Input
              aria-invalid={!!errors?.contact?.phone}
              {...register('contact.phone')}
            />
            {errors?.contact?.phone && (
              <Field.error>{errors.contact.phone.message}</Field.error>
            )}
          </Field>
        </Grid>
      </Fieldset>
      <Fieldset variant="secondary">
        <Fieldset.legend variant="secondary">Bankkonto</Fieldset.legend>
        <Grid gap={3} columns={[1, 2]}>
          <Field>
            <Field.label htmlFor="bank.iban">IBAN</Field.label>
            <Input
              css={{ fontVariantNumeric: 'lining-nums' }}
              aria-invalid={!!errors.iban}
              {...register('bank.iban')}
            />
          </Field>
          <Field>
            <Field.label htmlFor="bank.bic">BIC (optional)</Field.label>
            <Input
              placeholder=""
              aria-invalid={!!errors.bic}
              {...register('bank.bic')}
            />
          </Field>
        </Grid>
      </Fieldset>
      <Fieldset variant="secondary">
        <Fieldset.legend variant="secondary">Sicherheit</Fieldset.legend>
        <Grid gap={3} columns={[1, 2]}>
          <Field>
            <Field.label htmlFor="password">Passwort</Field.label>
            <Input
              type="password"
              placeholder=""
              aria-invalid={!!errors.password}
              {...register('password')}
            />
          </Field>
        </Grid>
      </Fieldset>
      <Divider />
      <Flex mt={5} sx={{ alignItems: 'center' }}>
        <Button
          disabled={formState.isSubmitting}
          type="submit"
          sx={{ fontSize: [2, 2, 3] }}
        >
          Speichern
        </Button>
        {isSubmitting && <Spinner ml={3} size={24} />}
      </Flex>
    </Box>
  )
}

Form.propTypes = {
  onDone: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
}

// Data

function GeneralData({ name, website }) {
  return (
    <>
      <Datum>
        <DatumLabel>Anzeigename</DatumLabel>
        <DatumValue>{name || 'unbekannt'}</DatumValue>
      </Datum>
      <Datum>
        <DatumLabel>Webseite</DatumLabel>
        <DatumValue>{website || 'unbekannt'}</DatumValue>
      </Datum>
    </>
  )
}

function CompanyData({ name, vatNumber, address }) {
  return (
    <>
      <Heading mb={1} as="h4">
        Unternehmen
      </Heading>
      <Datum>
        <DatumLabel>Firmenwortlaut</DatumLabel>
        <DatumValue>{name || 'unbekannt'}</DatumValue>
      </Datum>
      <Datum>
        <DatumLabel>Umsatzsteuer-Identifikationsnummer</DatumLabel>
        <DatumValue>{vatNumber || 'unbekannt'}</DatumValue>
      </Datum>
      <Datum>
        <DatumLabel>Adresse</DatumLabel>
        <DatumValue>{formatPostalAddress(address)}</DatumValue>
      </Datum>
    </>
  )
}

function BankData({ iban, bic }) {
  return (
    <>
      <Heading mb={1} as="h4">
        Bankkonto
      </Heading>
      <Datum>
        <DatumLabel>IBAN</DatumLabel>
        <DatumValue>{iban || 'unbekannt'}</DatumValue>
      </Datum>
      <Datum>
        <DatumLabel>BIC</DatumLabel>
        <DatumValue>{bic || 'unbekannt'}</DatumValue>
      </Datum>
    </>
  )
}

function ContactData({ firstname, lastname, email, phone }) {
  return (
    <>
      <Heading mb={1} as="h4">
        Kontakt
      </Heading>
      <Datum>
        <DatumLabel>Name</DatumLabel>
        <DatumValue>
          {firstname} {lastname}
        </DatumValue>
      </Datum>
      <Datum>
        <DatumLabel>Email</DatumLabel>
        <DatumValue>{email}</DatumValue>
      </Datum>
      <Datum>
        <DatumLabel>Telefon</DatumLabel>
        <DatumValue>{phone}</DatumValue>
      </Datum>
    </>
  )
}
