import { Button } from 'theme-ui'
import PropTypes from 'prop-types'
import React from 'react'
import { Alert, Box, Text } from 'theme-ui'

const FailureNotice = ({ onRetry }) => (
  <Alert variant="info">
    <Box>
      <Text as="p" mb={3}>
        Leider ist etwas schief gelaufen. Du kannst nochmal versuchen in dem du
        die Seite neu ladest oder auf den Button “Auf ein neues” klickst. Wenn
        das noch immer nicht geklappt hat dann bitte schreibe uns auf{' '}
        <a color="inherit" href="mailto: hallo@engimono.io">
          hallo@engimono.io
        </a>
        .
      </Text>
      {onRetry && (
        <Button variant="outline" onClick={onRetry}>
          Auf ein neues …
        </Button>
      )}
    </Box>
  </Alert>
)

FailureNotice.propTypes = {
  onRetry: PropTypes.func,
}

export default FailureNotice
