import { de } from 'date-fns/locale'
import { formatDistance } from 'date-fns'
import React from 'react'
import { useQuery } from 'react-query'
import { Box, Card, Flex, Grid, Spinner, Text } from 'theme-ui'
import { DataHeading } from './'

function Stat({
  label,
  subHeading,
  heading,
  primaryValue,
  previousValue,
  secondaryValue,
}) {
  return (
    <Card
      sx={{
        p: 3,
        justifyContent: 'flex-end',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Text
        sx={{
          color: 'muted',
          fontSize: 0,
          mb: 1,
        }}
      >
        {heading}
      </Text>
      <Box sx={{ mb: 2 }}>
        <Flex sx={{ flexDirection: 'row', alignItems: 'center' }}>
          <Text
            sx={{
              color: 'primary',
              fontWeight: 700,
              fontSize: 4,
            }}
          >
            {primaryValue}
          </Text>
          {previousValue !== undefined ? (
            <Text
              sx={{
                color: 'muted',
                ml: 1,
                fontSize: 1,
              }}
            >
              / {previousValue}
            </Text>
          ) : null}
        </Flex>
        {secondaryValue !== undefined ? (
          <Text
            sx={{
              color: 'muted',
              fontSize: 0,
            }}
          >
            {secondaryValue}
          </Text>
        ) : null}
      </Box>
      <DataHeading>{label}</DataHeading>
      {subHeading ? (
        <Text
          sx={{
            color: 'muted',
            fontSize: 0,
            fontStyle: 'italic',
          }}
        >
          {subHeading}
        </Text>
      ) : null}
    </Card>
  )
}

export function Dashboard({ partnerId, producer, producerApi }) {
  const queryString = partnerId ? `?partnerId=${partnerId}` : ''
  const { isLoading, data } = useQuery(
    ['stats', producer.producerId, partnerId],
    async () => {
      const data = await producerApi
        .get(`producers/${producer.producerId}/stats${queryString}`)
        .json()

      return data
    }
  )

  if (isLoading) {
    return (
      <Flex sx={{ justifyContent: 'center' }}>
        <Box>
          <Spinner size={24} />
        </Box>
      </Flex>
    )
  }

  if (!data.lastPolicy) {
    return (
      <Text>
        Leider gibt es noch keine Daten um etwas am Dashboard anzuzeigen.
      </Text>
    )
  }

  return (
    <Grid columns={[1, 2, 3]}>
      {producer.type !== 'Händler' && data.estimatedMonthlyRevenue ? (
        <Stat
          heading="Aktueller Monat"
          label="Prämie"
          subHeading="prognostiziert"
          primaryValue={new Intl.NumberFormat('de-AT', {
            style: 'currency',
            currency: 'EUR',
          }).format(parseInt(data.estimatedMonthlyRevenue.value, 10) / 100)}
        />
      ) : null}
      <Stat
        heading="Aktueller Monat"
        label="Abschlüsse"
        subHeading="Monat / Vormonat"
        primaryValue={data.currentMonth.value}
        previousValue={data.lastMonth.value}
      />
      <Stat
        heading="Aktuelle Woche"
        label="Abschlüsse"
        subHeading="Woche / Vorwoche"
        primaryValue={data.currentWeek.value}
        previousValue={data.lastWeek.value}
      />
      <Stat
        heading="Heute"
        label="Abschlüsse"
        subHeading="Heute / Gestern"
        primaryValue={data.today.value}
        previousValue={data.yesterday.value}
      />
      <Stat
        heading="Insgesamt"
        label="Aktive Polizzen"
        subHeading="seit Beginn"
        primaryValue={data.totalPolicies.value}
      />
      <Stat
        label="Letzter Abschluss"
        primaryValue={new Intl.DateTimeFormat('de-AT').format(
          new Date(data?.lastPolicy?.value)
        )}
        subHeading={formatDistance(
          new Date(data?.lastPolicy?.value),
          new Date(),
          {
            addSuffix: true,
            locale: de,
          }
        )}
      />
    </Grid>
  )
}
