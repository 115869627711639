import ky from "ky";
import { assign, Machine } from "xstate";
import { schemaOnSave } from "./form-model";

const API_URL = process.env.GATSBY__PRODUCER_ONBOARDING_API_URL;

export const initContext = ({ navigate, token }) => ({
  apiFetch: ky.extend({
    prefixUrl: API_URL,
    hooks: {
      beforeRequest: [
        (request) => {
          request.headers.set("Authorization", `Bearer ${token}`);
        },
      ],
    },
  }),
  navigate,
  drafts: [],
  finalized: [],
});

export const machine = new Machine(
  {
    id: "api",
    initial: "fetch",
    states: {
      idle: {
        on: {
          CREATE: "create",
          DELETE: "delete",
          FINALIZE: "finalize",
          UPDATE: {
            target: "update",
            cond: "isValidForSaving",
          },
        },
      },
      create: {
        invoke: {
          src: (context, event) =>
            context.apiFetch
              .post("drafts", {
                json: {
                  ...event.data.values,
                },
              })
              .json(),
          onDone: {
            target: "fetch",
            actions: (context, event) => context.navigate(`form/${event.data.producerDraftId}`),
          },
          onError: {
            target: "failure",
          },
        },
      },
      update: {
        invoke: {
          src: (context, event) =>
            context.apiFetch
              .patch(`drafts/${event.draftId}`, { json: event.data })
              .json(),
          onDone: {
            target: "idle",
            actions: assign({
              drafts: (context, event) =>
                context.drafts.reduce((agg, curr) => {
                  if (curr.producerDraftId === event.data.producerDraftId) {
                    agg.push(event.data);
                  } else {
                    agg.push(curr);
                  }
                  return agg;
                }, []),
            }),
          },
          onError: {
            target: "failure",
          },
        },
      },
      finalize: {
        onEntry: assign({
          finalizedDraft: (_context, event) => ({
            ...event.data,
            producerDraftId: event.draftId,
          }),
        }),
        invoke: {
          src: (context, event) =>
            context.apiFetch
              .post(`drafts/${event.draftId}/finalize`, {
                json: event.data,
              })
              .json(),
          onDone: {
            target: "idle",
            actions: [
              assign((context) => {
                const { finalizedDraft } = context;
                finalizedDraft.finalizedAt = new Date();
                const drafts = context.drafts.filter(
                  (draft) => draft.producerDraftId !== finalizedDraft.producerDraftId,
                );
                return {
                  ...context,
                  finalizedDraft: null,
                  finalized: [finalizedDraft, ...context.finalized],
                  drafts,
                };
              }),
              (context) => {
                context.navigate("../../success", {
                  state: context.finalizedDraft,
                });
              },
            ],
          },
          onError: {
            target: "failure",
          },
        },
      },
      delete: {
        invoke: {
          src: (context, event) => {
            const draftId = event.data.draftId;
            return context.apiFetch.delete(`drafts/${draftId}`).json();
          },
          onDone: {
            target: "idle",
            actions: assign({
              drafts: (context, event) => {
                const drafts = context.drafts.filter(
                  (draft) => draft.producerDraftId !== event.data.producerDraftId,
                );
                return drafts;
              },
            }),
          },
          onError: {
            target: "failure",
          },
        },
      },
      fetch: {
        invoke: {
          src: (context) => context.apiFetch.get("drafts").json(),
          onDone: {
            target: "idle",
            actions: assign((context, event) => {
              const data = event.data.reduce(
                (agg, curr) => {
                  curr.finalizedAt
                    ? agg.finalized.push(curr)
                    : agg.drafts.push(curr);
                  return agg;
                },
                { finalized: [], drafts: [] },
              );
              return {
                ...context,
                ...data,
              };
            }),
          },
          onError: {
            target: "failure",
          },
        },
      },
      failure: {},
    },
  },
  {
    guards: {
      isValidForSaving: async (_context, event) => {
        const { data } = event;
        const valid = await schemaOnSave.isValid(data);
        return valid;
      },
    },
  },
);
