import React from "react";
import { Label, Box, Text, Heading } from "theme-ui";

export const SectionHeading = ({ children }) => (
  <Heading
    sx={{
      mb: 3,
      fontSize: 3,
    }}
  >
    {children}
  </Heading>
);

export const Field = ({ children, ...props }) => (
  <Box {...props}>{children}</Box>
);

Field.error = React.forwardRef((props, ref) => (
  <Text {...props} mt={1} color="danger.text" />
));

Field.label = Label;

export const Fieldset = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="fieldset"
    variant="primary"
    {...props}
    __themeKey="fieldsets"
    __css={{
      p: 0,
      m: 0,
      border: 0,
      "&:disabled > *:not(legend)": {
        opacity: 0.6,
      },
    }}
  />
));

Fieldset.legend = React.forwardRef((props, ref) => (
  <Box
    ref={ref}
    as="legend"
    variant="primary"
    {...props}
    __themeKey="legends"
    __css={{
      display: "block",
    }}
  />
));

export const Datum = ({ children }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: ["column", "row"],
      alignItems: "baseline",
      justifyContent: "space-between",
      "&:after": {
        content: "\"\"",
        flex: 1,
        mx: [0, 1],
        my: [2, 0],
        width: "100%",
        order: [2, 1],
        borderBottom: "1px dashed",
        borderColor: "gray.3",
      },
      "&:last-of-type:after": {
        display: ["none", "block"],
      },
    }}
  >
    {children}
  </Box>
);

export const DatumLabel = (props) => (
  <Text sx={{ color: "muted", fontWeight: "medium", order: 0 }} {...props} />
);

export const DatumValue = (props) => (
  <Text sx={{ order: [1, 2] }} {...props} />
);

export const DataHeading = ({ children }) => (
  <Heading
    variant="smallCaps"
    sx={{ color: 'muted', marginBottom: 1, fontSize: 0 }}
  >
    {children}
  </Heading>
)

